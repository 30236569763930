import React, { useState } from "react"
import { ButtonGroup, Button, TextField, Subheading } from "@shopify/polaris"
import update from "immutability-helper"
import styled from "styled-components"
const CouponWrapper = styled.div`
  padding-top: 1.5rem;
  .coupon-title {
    display: block;
    padding: 5px 0;
  }
  .coupon-items {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 15px;
    .coupon-item:first-of-type {
      flex: 0 0 auto;
    }
    .coupon-item:nth-child(2) {
      width: 125px;
    }
    .coupon-item:last-of-type {
      flex: 1 1 auto;
    }
  }
`

function DiscountCard(props) {
  const { state, setState, currency, currencySymbol = "$" } = props
  const [isFirstButtonActive, setIsFirstButtonActive] = useState(
    !state?.discount?.sym || state?.discount?.sym === "$" ? true : false
  )
  const handleFirstButtonClick = () => {
    const discount = { sym: currencySymbol, value: "" }
    if (isFirstButtonActive) return
    setState(update(state, { discount: { $set: discount } }))
    setIsFirstButtonActive(true)
  }

  const handleSecondButtonClick = () => {
    const discount = { sym: "%", value: "" }
    setState(update(state, { discount: { $set: discount } }))
    setIsFirstButtonActive(false)
  }
  return (
    <CouponWrapper>
      <Subheading>DISCOUNT</Subheading>
      <div className="coupon-items">
        <div className="coupon-item">
          <ButtonGroup segmented>
            <Button
              pressed={isFirstButtonActive}
              onClick={() => handleFirstButtonClick()}
            >
              {currencySymbol}
            </Button>
            <Button
              pressed={!isFirstButtonActive}
              onClick={() => handleSecondButtonClick()}
            >
              %
            </Button>
          </ButtonGroup>
        </div>
        <div className="coupon-item">
          <TextField
            onChange={(val) => {
              const newVal = !isFirstButtonActive && val > 100 ? 100 : val
              setState(
                update(state, {
                  discount: {
                    $set: {
                      sym: isFirstButtonActive ? currencySymbol : "%",
                      value: newVal,
                    },
                  },
                })
              )
            }}
            value={
              state?.discount?.sym
                ? String(state.discount.value)
                : state.discount
            }
            min={0}
            max={!isFirstButtonActive ? 100 : null}
            type={"text"}
            suffix={isFirstButtonActive ? currency : "%"}
          />
        </div>
      </div>
    </CouponWrapper>
  )
}

export default DiscountCard
